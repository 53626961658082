import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useReach } from "../hooks/useReach";
import useCopy from "../hooks/useCopy";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import CheckIcon from "@mui/icons-material/Check";
import {
  formatCompactAddress,
  getAccountAssets,
  getAccountInfo,
  getProviderAdminTokens,
} from "../functions";
import Icon from "react-crypto-icons";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import useLocalStorage from "../hooks/useLocalStorage";
import CasinoIcon from "@mui/icons-material/Casino";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import appService from "../services/appService";
import { getApplicationAddress } from "algosdk";
import * as backend from "../build/dice/index.main.mjs";

const ConnectButton = ({ onConnect, onDisconnect, addr, bal }) => {
  const copy = useCopy();

  const providerEnv = localStorage.getItem("providerEnv") || "MainNet";
  const walletFallback =
    localStorage.getItem("walletFallback") || "MyAlgoConnect";

  const handleProviderSelect = (providerEnv) => {
    localStorage.setItem("providerEnv", providerEnv);
    window.location.reload();
  };
  return (
    <>
      {!addr && (
        <ButtonGroup className="wallet-connect">
          <Dropdown as={ButtonGroup}>
            <Button
              style={{
                color: "black",
                background: "inherit",
                borderColor: "black",
                border: "1",
              }}
              onClick={onConnect}
            >
              Connect
            </Button>
            <Dropdown.Toggle
              style={{
                color: "black",
                background: "inherit",
                borderColor: "black",
                border: "1",
              }}
              split
              id="dropdown-basic"
            />
            <Dropdown.Menu>
              {false && (
                <Dropdown.Item
                  onClick={() => {
                    localStorage.setItem("walletFallback", "Mnemonic");
                    window.location.reload();
                  }}
                >
                  {walletFallback === "Mnemonic" && <CheckIcon />}
                  Mnemonic
                </Dropdown.Item>
              )}
              <Dropdown.Item
                onClick={() => {
                  localStorage.setItem("walletFallback", "MyAlgoConnect");
                  window.location.reload();
                }}
              >
                {walletFallback === "MyAlgoConnect" && <CheckIcon />}
                My Algo Connect
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  localStorage.setItem("walletFallback", "WalletConnect");
                  window.location.reload();
                }}
              >
                {walletFallback === "WalletConnect" && <CheckIcon />}
                Wallet Connect
              </Dropdown.Item>
              {!false && (
                <>
                  <Dropdown.Divider />
                  {["MainNet", "TestNet"].map((el) => (
                    <Dropdown.Item onClick={() => handleProviderSelect(el)}>
                      {providerEnv === el && <CheckIcon />}
                      {el}
                    </Dropdown.Item>
                  ))}
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </ButtonGroup>
      )}
      {addr && (
        <Dropdown as={ButtonGroup}>
          <Button
            style={{
              color: "white",
              fill: "white",
              background: "black",
              border: "0",
              borderColor: "black"
            }}
            onClick={() => copy(addr)}
          >
            {formatCompactAddress(addr)}{" "}
            {false && (
              <>
                <Icon size={12} name="algo" /> {bal}
              </>
            )}
          </Button>
          <Dropdown.Toggle
            style={{
              color: "white",
              fill: "white",
              background: "black",
              border: "0",
              borderColor: "black"
            }}
            split
            id="dropdown-basic"
          />
          <Dropdown.Menu>
            <Dropdown.Item onClick={onDisconnect}>Disconnect</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};

const ButtonAppBar = (props) => {
  const navigate = useNavigate();
  const reach = useReach();
  return (
    <Box
      sx={{ flexGrow: 1 }}
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "19px",
      }}
    >
      <Typography variant="h6" style={{ display: "inline" /*color: "white"*/ }}>
        <span>
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {process.env.REACT_APP_BRAND_NAME || "Tasty"}
          </span>
        </span>{" "}
      </Typography>
      <div>
        {false && <CleaningServicesIcon onClick={props.onCleanup} />}
        {props.addr && props.showAddCircle && (
          <>
            <AddCircleIcon
              className="d-xs-none"
              style={
                {
                  /*color: "white"*/
                }
              }
              onClick={() => navigate(`/dice`)}
            />
          </>
        )}
        <ConnectButton {...props} />
        {false && <SettingsIcon style={{ color: "white" }} />}
      </div>
    </Box>
  );
};

export const withAppBar = (Component) => {
  return (props) => {
    const reach = useReach();
    const [addr, setAddr] = useLocalStorage("addr", null);
    const navigate = useNavigate();
    const initialState = {
      acc: null,
    };
    const [state, setState] = React.useState(initialState);
    React.useEffect(() => {
      if (addr) {
        handleConnect();
      }
    }, []);
    const handleConnect = async () => {
      try {
        //console.log("Connecting ...");
        let acc;
        if (addr) {
          acc = await reach.connectAccount({ addr });
          //console.log({ acc });
        } else {
          acc = await reach.getDefaultAccount();
          setAddr(acc.networkAccount.addr);
          window.location.reload();
        }
        //const balAtomic = await reach.balanceOf(acc);
        //const bal = reach.formatCurrency(balAtomic, 4);
        const balAtomic = reach.parseCurrency(0);
        const bal = 0.0;
        //console.log(2);
        //const { assets }  = await getAccountAssets(acc.networkAccount.addr);
        const assets = [];
        let next = undefined;
        do {
          const res = await getAccountAssets(
            //"LATYT7TD52YSITAJRJQLYLMV3BYESLHHEMAAQ5W2VAS6UB3RLA4LENU5WM",
            acc.networkAccount.addr,
            {
              next,
            }
          );
          //console.log(res);
          //console.log(3);
          //console.log({ res });
          next = res["next-token"];
          assets.push(res.assets);
        } while (next);
        //console.log({ acc, assets: assets.flatMap(el => el) });
        setState({
          ...state,
          acc: {
            ...acc,
            assets: assets.flatMap((el) => el),
          },
          addr,
          balAtomic,
          bal,
          showAddCircle: ((toks) =>
            assets
              .flatMap((el) => el)
              .map((el) => el["asset-id"])
              .reduce((acc, val) => acc || toks.includes(val), false))(
            getProviderAdminTokens()
          ),
        });
      } catch (e) {
        alert(e);
      }
    };
    const handleDisconnect = () => {
      localStorage.removeItem("addr");
      window.location.reload();
    };
    const handleCleanup = async () => {
      const stored = localStorage.getItem("claim");
      if(!stored) return;
      const { appId, assetId, index } = JSON.parse(stored);
      const ctc = state.acc.contract(backend, appId);
      await reach.transfer(state.acc, state.acc, 0, assetId);
      await ctc.a.claim(index);
      /*
      console.log(ctc);
      console.log(ctc.getABI(true));
      */
      localStorage.removeItem("claim");
    };
    return (
      <>
        <ButtonAppBar
          onConnect={handleConnect}
          onDisconnect={handleDisconnect}
          onCleanup={handleCleanup}
          addr={state.addr}
          bal={state.bal}
          showAddCircle={state.showAddCircle}
        />

        <Component {...props} acc={state.acc} />
      </>
    );
  };
};
