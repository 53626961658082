import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import "./App.css";
import {
  fetchAssetImage,
  getAccountAssets,
  getAccountInfo,
  getAsset,
  getProviderAdminTokens,
  placeholderImage,
  randomArchirand,
  shuffle,
} from "./functions";
import {
  CircularProgress,
  Divider,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import appService from "./services/appService";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// AssetSelector
import AssetSellector from "./components/AssetSelector";

// Noodle
import { useReach } from "./hooks/useReach";
import useLocalStorage from "./hooks/useLocalStorage";

import { styled } from "@mui/material/styles";

import React, { useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";

import { CubeTextureLoader, MeshBasicMaterial, CubeTexture } from "three";
import LogoCube from "./LogoCube";
import { Card, Col, Image, Row } from "react-bootstrap";
import classNames from "classnames";
import archirand from "./statics/at";

const DICE_SIZE = 6;

function App() {
  const reach = useReach();
  const [addr, setAddr] = useLocalStorage("addr", null);
  const navigate = useNavigate();
  const { poolId } = useParams();
  const [_, asset0, asset1, asset2, asset3, asset4, asset5] = poolId
    ? poolId.split("-")
    : [];
  const initialState = {
    acc: null,
    addrs:
      localStorage.getItem("state") &&
      (Object.keys(JSON.parse(localStorage.getItem("state"))?.memo2) || []).map(
        (el) => ({ addr: el })
      ),
    success: false,
    confetti: false,
  };
  const inputRef = useRef(null);
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({
    ASSET0: asset0,
    ASSET1: asset1,
    ASSET2: asset2,
    ASSET3: asset3,
    ASSET4: asset4,
    ASSET5: asset5,
  });
  const [selection, setSelection] = useState([]);

  useEffect(() => {
    if (addr) {
      handleConnect();
    }
  }, []);

  /*
  useEffect(() => {
    console.log(selection);
  });
  */

  const handleChange = async ({ target }) => {
    let { name, value } = target;
    switch (name) {
      case "DICE":
        value = value.split(/\r?\n/).join("-");
        break;
      case "ASSETID":
        let { id: newId = 0, decimals: DECIMALS, creator: CREATOR } = value;
        // try again to get asset info if not in option value
        if (!DECIMALS) {
          let { decimals } = await getAsset(newId);
          DECIMALS = decimals;
        }
        setQuery({
          ...query,
          [name]: newId,
          DECIMALS,
        });
        break;
      case "SWAPT":
      case "SWAPF":
      case "INFO":
      case "EXCHANGE":
      case "PASS":
      case "PLAN":
      case "AMT":
      case "TYPE":
      case "METHOD":
      case "SKIPCHECK":
        value = parseInt(value);
        break;
      default:
        break;
    }
    setQuery({ ...query, [name]: value });
  };

  const handleConnect = async () => {
    try {
      console.log("Connecting ...");
      let acc;
      if (addr) {
        acc = await reach.connectAccount({ addr });
      } else {
        acc = await reach.getDefaultAccount();
        setAddr(acc.networkAccount.addr);
        window.location.reload();
      }
      //const balAtomic = await reach.balanceOf(acc);
      //const bal = reach.formatCurrency(balAtomic, 4);
      const balAtomic = reach.parseCurrency(0);
      const bal = 0;
      //const { assets }  = await getAccountAssets(acc.networkAccount.addr);
      const assets = [];
      let next = undefined;
      do {
        const res = await getAccountAssets(
          //"LATYT7TD52YSITAJRJQLYLMV3BYESLHHEMAAQ5W2VAS6UB3RLA4LENU5WM",
          acc.networkAccount.addr,
          {
            next,
          }
        );
        //console.log({ res });
        next = res["next-token"];
        assets.push(res.assets);
      } while (next);
      //console.log({ acc, assets: assets.flatMap((el) => el) });
      // token gate
      if (!(toks => assets.flatMap(el => el).map(el => el["asset-id"]).reduce((acc,val) => acc || (toks).includes(val), false))(getProviderAdminTokens())) {
        navigate("/");
      }
      /*
      const assets = {};
      const images = {};
      console.log("Loading assets ...");
      for (let i in accInfo.account?.assets ?? []) {
        const asset = accInfo.account?.assets[i];
        const assetId = asset["asset-id"];
        const amount = asset["amount"];
        if (amount === 0) {
          continue;
        }
        let asa = await getAsset(assetId).catch(console.dir);
        if (!asa?.asset?.params?.url) {
          // guard asa
          console.log("Skipping asa...");
          continue;
        }
        let image = await fetchAssetImage(asa);
        if (image.match(/#arc3/)) {
          // guard image
          console.log("Skipping image...");
          continue;
        }
        assets[assetId] = asa;
        images[assetId] = image;
      }
      console.log({ assets, images });
      */
      setState({
        ...state,
        acc: {
          ...acc,
          assets: assets.flatMap((el) => el),
        },
        assets: assets.flatMap((el) => el),
        //images,
        addr,
        balAtomic,
        bal,
      });
    } catch (e) {
      alert(e);
    }
  };

  const handleDisconnect = () => setState(initialState);

  const handleDiceCreate = async () => {
    // TODO add validation
    setLoading(true);
    if (selection.length > 0) {
      const diceId = [...selection, 88455115].join("-")
      navigate(`/dice/0-${diceId}`);
    } else {
      const { DICE: diceId } = query;
      navigate(`/dice/0-${diceId}`);
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return state.acc ? (
    <>
      <Container className="pb-5">
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" className="text-center text-dark">
            -Build dice-
          </Typography>
        </Box>
        <Box sx={{ mb: 3 }}>
          {selection.length > 0 ? (
            <TextField
              disabled={selection.length > 0}
              name="DICE"
              fullWidth
              variant="standard"
              id="outlined-multiline-flexible"
              maxRows={7}
              value={selection.join(",")}
              onChange={handleChange}
            />
          ) : (
            <TextField
              name="DICE"
              fullWidth
              variant="standard"
              id="outlined-multiline-flexible"
              label="Multiline"
              multiline
              maxRows={DICE_SIZE}
              onChange={handleChange}
            />
          )}
        </Box>
        <Box sx={{ mb: 3 }}>
          <Button
            onClick={() => {
              const newSelection = (state.acc?.assets ?? [])
                .map((el) => ({
                  index: el["asset-id"],
                  amount: el.amount,
                }))
                .filter(({ index, amount }) => amount > 0)
                .map(({ index }) => index)
                .map(randomArchirand)
                .filter((index) => index > 0);
              //console.log({newSelection})
              setSelection(shuffle(newSelection).slice(0, DICE_SIZE));
            }}
          >
            Random
          </Button>
        </Box>
        <Grid container>
          {!state.acc
            ? archirand.map(({ index }) => (
                <Grid
                  item
                  xs={3}
                  md={2}
                  xl={1}
                  style={{
                    border: selection.includes(index)
                      ? "2px solid lightgreen"
                      : "",
                  }}
                >
                  <Image
                    fluid
                    src={`/assets/${index}.png`}
                    onClick={() => {
                      if (selection.includes(index)) {
                        setSelection(selection.filter((el) => el !== index));
                      } else {
                        if (selection.length >= DICE_SIZE) {
                          //alert("over selection")
                          return;
                        }
                        setSelection(
                          Array.from(new Set([...selection, index]))
                        );
                      }
                    }}
                  />
                </Grid>
              ))
            : (state.acc?.assets ?? [])
                .map((el) => ({ index: el["asset-id"], amount: el.amount }))
                .filter(({ index, amount }) => amount > 0)
                .map(({ index }) => index)
                .map(randomArchirand)
                .filter((index) => index > 0)
                .map((index) => (
                  <>
                    <Grid
                      item
                      xs={3}
                      md={2}
                      xl={1}
                      style={{
                        border: selection.includes(index)
                          ? "2px solid lightgreen"
                          : "",
                      }}
                    >
                      <Image
                        fluid
                        src={`/assets/${index}.png`}
                        onClick={() => {
                          if (selection.includes(index)) {
                            setSelection(
                              selection.filter((el) => el !== index)
                            );
                          } else {
                            if (selection.length >= DICE_SIZE) {
                              //alert("over selection")
                              return;
                            }
                            setSelection(
                              Array.from(new Set([...selection, index]))
                            );
                          }
                        }}
                      />
                    </Grid>
                  </>
                ))}
        </Grid>
      </Container>
      <Box sx={{ m: 5 }}>
        <Button
          variant="primary"
          style={{
            position: "fixed",
            bottom: "0px",
            left: "0px",
          }}
          onClick={handleDiceCreate}
          className="w-100 rounded-0"
        >
          Create Dice
        </Button>
      </Box>
    </>
  ) : (
    <div
      style={{
        textAlign: "center",
        height: "60vh",
        verticalAlign: "middle",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        color: "black",
        fontSize: "30px",
      }}
    >
      <CircularProgress color="inherit" size={100} />
    </div>
  );
}

export default App;
