import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import "./App.css";
import {
  explorerLink,
  getAccountAssets,
  getAccountInfo,
  getAsset,
  randomArchirand,
  shuffle,
  somethingFromSome,
} from "./functions";
import { CircularProgress, Grid, Modal, Paper } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import appService from "./services/appService";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useReach } from "./hooks/useReach";
import useLocalStorage from "./hooks/useLocalStorage";
import { styled } from "@mui/material/styles";
import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { CubeTextureLoader, MeshBasicMaterial, CubeTexture } from "three";
import { ButtonGroup, Image, Spinner } from "react-bootstrap";
import { getApplicationAddress } from "algosdk";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import Icon from "react-crypto-icons";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import POW from "./POWLOGO-black.svg";
import * as diceBackend from "./build/dice/index.main.mjs";
import * as backend from "./build/dice/index.main.mjs";
import axios from "axios";
import ionService from "./services/ionService";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const GRID_SIZE = 16;

const colors = [
  "#84DE02",
  "#E88E5A",
  "#DDE26A",
  "#C53151",
  "#FFDF46",
  "#B05C52",
  "#FF4466",
  "#828E84",
  "#FD5240",
  "#391285",
  "#FF85CF",
  "#FF4681",
  "#4BC7CF",
  "#FF6D3A",
  "#FF404C",
  "#A0E6FF",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  maxWidth: "500px",
  //height: "80vh",
  background: "white",
  //border: "1px solid #ccc",
  boxShadow: 24,
  p: 5,
  textAlign: "center",
  paddingBottom: "5em",
  borderRadius: "1em",
  overflow: "hidden",
};

function Dice(props) {
  const loader = new CubeTextureLoader();
  loader.setPath("/");
  const images = randomArchirand(0).slice(0, 6);
  const textureCube = loader.load(images);
  const material = new MeshBasicMaterial({
    color: 0xffffff,
    envMap: textureCube,
  });

  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef();
  // Hold state for hovered and clicked events
  const [hovered, hover] = useState(false);
  const [clicked, click] = useState(false);
  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => {
    ref.current.rotation.x += 0.01;
    ref.current.rotation.y += 0.01;
  });
  // Return the view, these are regular Threejs elements expressed in JSX
  return (
    <mesh
      {...props}
      ref={ref}
      scale={clicked ? 2 : 1}
      onClick={(event) => click(!clicked)}
      onPointerOver={(event) => hover(true)}
      onPointerOut={(event) => hover(false)}
    >
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={"orange"} />
    </mesh>
  );
}

const {
  REACT_APP_NETWORK_PROVIDER,
  REACT_APP_PPC_SHUFFLE,
  REACT_APP_PPC_DICE_GO,
  REACT_APP_PPC_DICE_ROLL,
} = process.env;

const providerEnv =
  REACT_APP_NETWORK_PROVIDER ||
  localStorage.getItem("providerEnv") ||
  "MainNet";

function App() {
  const { width, height } = useWindowSize();
  const reach = useReach();
  //const node = useIPFS();
  const [addr, setAddr] = useLocalStorage("addr", null);
  const navigate = useNavigate();
  const { cid, poolId } = useParams();
  const [_, asset0, asset1, asset2, asset3, asset4, asset5, asset6] = poolId
    ? poolId.split("-")
    : [];
  const initialState = {
    acc: null,
    addrs:
      localStorage.getItem("state") &&
      (Object.keys(JSON.parse(localStorage.getItem("state"))?.memo2) || []).map(
        (el) => ({ addr: el })
      ),
    success: false,
    confetti: false,
  };
  const [app, setApp] = useState(null);
  const [goingToDice, setGoingToDice] = useState(false);
  const [display, setDisplay] = useState(null);
  const [showDiceModal, setShowDiceModal] = useState(false);
  const [loadingClaim, setLoadingClaim] = useState(false);
  const [open, setOpen] = useState(false);
  const [next, setNext] = useState(null);
  const [side, setSide] = useState(0);
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [speed, setSpeed] = useState(5000);
  const [query, setQuery] = useState({
    ASSET0: asset0,
    ASSET1: asset1,
    ASSET2: asset2,
    ASSET3: asset3,
    ASSET4: asset4,
    ASSET5: asset5,
    ASSET6: asset6,
  });
  const [selection, setSelection] = useState([]);
  const [counter, setCounter] = useState(-1);

  //useEffect(() => {
  //(async () => {
  //console.log({ cid });
  /*
      const stream = (await node).cat(
        cid ?? "QmY2baogpZCuoSdSxrrt9Rm6hPgVmiqhDdBbqkp3N6aoHA"
      );
      let data = "";
      for await (const chunk of stream) {
        data += Buffer.from(chunk).toString("utf8");
      }
      console.log(data);
      */
  //const res = await ionService.get(cid);
  //console.log(res);
  //return;
  //const app = JSON.parse(data);
  //const app = data;
  //console.log({ app });
  //setApp(app);
  //})();
  //}, []);

  useEffect(() => {
    if (addr) {
      handleConnect();
    }
  }, []);

  useEffect(() => {
    if (!state.acc || !app) return;
    const timeout = setTimeout(() => loadAssets(false), 1);
    return () => clearTimeout(timeout);
  }, [state.acc, app]);

  useEffect(() => {
    if (!state.acc) return;
    (async () => {
      const ctc = state.acc.contract(diceBackend, side);
      const next = somethingFromSome(
        (v) => reach.bigNumberToNumber(v),
        0
      )(await ctc.v.next());
      const price = somethingFromSome(
        (v) => reach.formatCurrency(v),
        0
      )(await ctc.v.price());
      const asset = await getAsset(next);
      //console.log({next: { ...asset, price }})
      //setNext({ ...asset, price });
    })();
  }, [side]);

  useEffect(() => {
    let counter = 0;
    const interval = setInterval(() => setCounter(counter++), speed);
    return () => clearInterval(interval);
  }, [speed]);

  const handleClose = async () => {
    const ctc = state.acc.contract(backend, side);
    const p = somethingFromSome(
      (v) => v.map((w) => reach.formatAddress(w)),
      []
    )(await ctc.v.participants()).indexOf(state.acc.networkAccount.addr);
    const t = somethingFromSome(
      (v) => v.map((w) => reach.bigNumberToNumber(w)),
      []
    )(await ctc.v.tokens());
    //console.log(p, t);
    await reach.transfer(state.acc, state.acc, 0, next.asset.index);
    await ctc.a.claim(p);
    localStorage.setItem(
      "claim",
      JSON.stringify({
        appId: side,
        assetId: next.asset.index,
        index: p,
      })
    );
    window.location.reload();
  };

  const handleChange = async ({ target }) => {
    let { name, value } = target;
    //console.log({ name, value });
    switch (name) {
      case "ASSETID":
        let { id: newId = 0, decimals: DECIMALS, creator: CREATOR } = value;
        // try again to get asset info if not in option value
        if (!DECIMALS) {
          let { decimals } = await getAsset(newId);
          DECIMALS = decimals;
        }
        setQuery({
          ...query,
          [name]: newId,
          DECIMALS,
        });
        break;
      case "SWAPT":
      case "SWAPF":
      case "INFO":
      case "EXCHANGE":
      case "PASS":
      case "PLAN":
      case "AMT":
      case "TYPE":
      case "METHOD":
      case "SKIPCHECK":
        value = parseInt(value);
        break;
      default:
        break;
    }
    setQuery({ ...query, [name]: value });
  };

  const handleConnect = async () => {
    try {
      //console.log("Connecting ...");
      let acc;
      if (addr) {
        acc = await reach.connectAccount({ addr });
      } else {
        acc = await reach.getDefaultAccount();
        setAddr(acc.networkAccount.addr);
      }
      //const balAtomic = await reach.balanceOf(acc);
      //const bal = reach.formatCurrency(balAtomic, 4);
      const balAtomic = reach.parseCurrency(0);
      const bal = 0;
      const assets = [];
      let next = undefined;
      do {
        const res = await getAccountAssets(
          //"LATYT7TD52YSITAJRJQLYLMV3BYESLHHEMAAQ5W2VAS6UB3RLA4LENU5WM",
          acc.networkAccount.addr,
          {
            next,
          }
        );
        //console.log({ res });
        next = res["next-token"];
        assets.push(res.assets);
      } while (next);
      setState({
        ...state,
        acc: {
          ...acc,
          assets: assets.flatMap((el) => el),
        },
        assets: assets.flatMap((el) => el),
        addr,
        balAtomic,
        bal,
      });
    } catch (e) {
      alert(e);
    }
  };

  const loadAssets = async (isShuffle = true) => {
    //console.log("ROLLING");
    let dices;
    if (cid && app) {
      dices = shuffle(app.apps?.map((el) => ({ appId: el })));
    } else {
      dices = shuffle(await appService.getDices());
    }
    const sides = [];
    const bounty = [];
    for (let i in dices) {
      if (sides.length >= GRID_SIZE) {
        break;
      }

      const { appId } = dices[i];

      const ctc = state.acc.contract(backend, appId);

      const participants = somethingFromSome(
        (v) => v.map((w) => reach.formatAddress(w)),
        []
      )(await ctc.v.participants());
      if (participants.includes(state.acc.networkAccount.addr)) {
        continue;
      }

      const escrow = getApplicationAddress(appId);

      let account;
      try {
        const res = await getAccountInfo(escrow);
        account = res.account;
      } catch (e) {
        //appService.removeDice(appId);
        continue;
      }
      const { assets: allAssets, amount } = account;
      //console.log(allAssets);

      const assets = (allAssets ?? []).filter(
        // skip exchange token
        //(el) => el["asset-id"] !== 88455115
        () => true
      ); // XXX
      if (!assets) {
        continue;
      }

      const totalAmount = assets.reduce((acc, val) => acc + val.amount, 0);
      //console.log({ appId, totalAmount });

      // add dices
      //if (/*!isShuffle &&*/ amount > 1000000 && totalAmount === 0) {
      //  bounty.push({ assetId: 0, appId, type: "dice" });
      //  continue;
      //}

      /*
      const ctc = state.acc.contract(backend, appId);
      const next = somethingFromSome(
        (v) => reach.bigNumberToNumber(v),
        0
      )(await ctc.v.next());
      //console.log({ assets, amount, totalAmount, next });
      */

      // add sides
      for (let j in assets) {
        const asset = assets[j];
        const amount = asset["amount"];
        const assetId = asset["asset-id"];
        if (amount === 0) {
          //console.log({ appId });
          continue;
        }
        /*
        if (assetId !== next) {
          continue;
        }
        */
        /*
        if (isShuffle) {
          sides.push({
            j,
            assetId,
            appId,
            type: "side",
            next: assetId === next,
          });
        } else {
          */
        //if(assetId !== next) {
        sides.push({
          j,
          assetId,
          appId,
          type: "side",
          //next: assetId === next,
        });
        //}
        /*
        }
        */
      }
    }
    const finalSides = shuffle([...sides, ...bounty]).slice(0); //, GRID_SIZE);
    /*
    if (finalSides.length === GRID_SIZE && bounty.length > 0) {
      finalSides.splice(Math.floor(Math.random() * GRID_SIZE), 1, bounty[0]);
    }
    */
    //console.log({bounty})
    setState({ ...state, sides: finalSides, empty: bounty });
    setLoading(false);
    setNext(null);
    setSide(0);
    setCounter(counter + 1);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  //console.log({providerEnv});
  return (
    state.acc &&
    [
      {
        name: "Alien Tourism",
        image:
          "https://images.squarespace-cdn.com/content/v1/622e4016070ddd67abd3680c/083d4b09-4b73-458e-9087-23a9f2cc8d6a/Black_hole.png",
        appId: {
          TestNet: 92327653,
          //MainNet: 755612884
        },
      },
      /*
      {
        name: "Archirand",
        image: "https://images.squarespace-cdn.com/content/v1/623ca6aac1b552574aa15ba1/eb511936-6f5e-4a8c-bf4e-ba636d54d373/Castle+Collaboration.png?format=1500w",
        appId: 92120201
      },
      */
    ].map(({ name, image, appId }) => (
      appId[providerEnv] && <Item
        onClick={() => navigate(`/${appId[providerEnv]}`)}
        style={{
          cursor: "pointer",
          background: `url(${image})`,
          opacity: "0.8",
          height: "100px",
          width: "80vw",
          marginLeft: "10vw",
          marginBottom: "5vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h3" align="center" style={{ color: "white" }}>
          {name}
        </Typography>
      </Item>
    ))
  );
}

export default App;
