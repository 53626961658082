import "./App.css";
import { Route, Routes, useParams, Navigate } from "react-router-dom";
import { withAppBar } from "./hoc/withAppBar";
import Home from "./Home";
import Top from "./Top";
import Dice from "./Dice";
import Roll from "./Roll";
import Relay from "./Relay";
import Assets from "./Assets";
import Admin from "./Admin";

function App() {
  const params = useParams();
  return (
    <Routes>
      {<Route path="/" element={<Top />} />}
      {<Route path="/:appId" element={<Assets />} exact />}
      {/*<Route path="/admin/:appId" element={<Admin />} exact />*/}
    </Routes>
  );
}

export default withAppBar(App);
